import { ReactNode, FC, useEffect, useRef } from 'react'
import { Flex, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { appInitialized } from 'controllers/init'

const NavWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  const initialized = useRef(false)

  useEffect(() => {
    if (!initialized.current) {
      appInitialized(navigate)
      initialized.current = true
    }
  }, [])

  return (
    <VStack h='full' w='full' overflow='hidden' spacing={0}>
      <Flex h='full' w='full' overflow='hidden'>
        {children}
      </Flex>
    </VStack>
  )
}

export default NavWrapper
