import {
  VStack,
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  HStack
} from '@chakra-ui/react'
import Label from 'components/Label'

interface Props {
  value: number
  onChange: (value: number) => void
}

const TemperatureInput: React.FC<Props> = ({ value, onChange }) => {
  return (
    <VStack w='full' align={'flex-start'}>
      <Label>Temperature</Label>
      <Flex w='full' alignItems='center' pt={2}>
        <Slider
          aria-label='temperature-slider'
          value={value}
          min={0}
          max={2}
          step={0.1}
          onChange={onChange}
          flex='1'
          mr={4}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb boxSize={6}>
            <Flex>
              <Text fontSize='sm'>{value}</Text>
            </Flex>
          </SliderThumb>
        </Slider>
      </Flex>
      <HStack fontSize={'sm'} w='full' justify={'space-between'}>
        <Text>Less random</Text>
        <Text>More random</Text>
      </HStack>
    </VStack>
  )
}

export default TemperatureInput
