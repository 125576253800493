import {
  query,
  collection,
  onSnapshot,
  doc,
  setDoc,
  updateDoc,
  writeBatch,
  arrayUnion
} from 'firebase/firestore'

import _ from 'lodash'
import { db, generateId } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveClients } from 'model/actions'
import * as FaceSign from 'types/internal'

export const fetchClients = async () => {
  try {
    const q = query(collection(db, 'clients'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, FaceSign.Client> = {}
        sn.docs.forEach(doc => {
          res[doc.id] = doc.data() as FaceSign.Client
        })
        store.dispatch(receiveClients(res))
      },
      err => {
        console.log(`fetchClients error: ${err.message}`)
      }
    )
    addListener('clients', unsubscribe)
  } catch (e) {
    console.log('fetchClients error', e)
  }
}

export const dbCreateClient = async () => {
  const c: FaceSign.Client = {
    id: generateId(),
    title: 'New Client',
    keys: [],
    apiKeys: {},
    createdAt: _.now(),
    deletedAt: 0
  }
  const ref = doc(collection(db, 'clients'), c.id)
  setDoc(ref, c)
  return c.id
}

export const dbUpdateClient = async (
  clientId: string,
  upd: Partial<FaceSign.Client>
) => {
  const ref = doc(collection(db, 'clients'), clientId)
  await updateDoc(ref, upd)
}

export const dbAddApiKey = async (
  clientId: string,
  apiKey: FaceSign.ApiKey
) => {
  const batch = writeBatch(db)
  const ref = doc(db, 'clients', clientId)
  batch.update(ref, { [`apiKeys.${apiKey.id}`]: apiKey })
  batch.update(ref, { keys: arrayUnion(apiKey.key) })
  await batch.commit()
}
