import { VStack, Heading, Text } from '@chakra-ui/react'
import { FC } from 'react'

type Props = {
  title: string
  description: string
}

const AuthHeader: FC<Props> = ({ title, description }) => {
  return (
    <VStack spacing={4} px={{ base: 4, lg: 0 }}>
      <Heading size={{ base: 'md', lg: 'lg' }} pt={2} textAlign='center'>
        {title}
      </Heading>
      <Text color='gray.600' fontSize='md' pb={5} textAlign='center' maxW='xl'>
        {description}
      </Text>
    </VStack>
  )
}

export default AuthHeader
