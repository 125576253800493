import { HStack, Text, VStack } from '@chakra-ui/react'
import { getLogs } from 'controllers/twoFactor'
import { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { ILog } from 'types/back'
import _ from 'lodash'
import ReactJson from 'react-json-view'

const TwoFactorLogs = () => {
  const [logs, setLogs] = useState<ILog[]>([])
  const { interactionId } = useParams() as { interactionId: string }

  useEffect(() => {
    const run = async () => {
      const logsResponse = await getLogs(interactionId)
      setLogs(logsResponse as ILog[])
    }
    run()
  }, [interactionId])

  const firstTimestamp = useMemo(() => {
    if (logs.length > 0) {
      return logs[0].timestamp
    } else {
      return 0
    }
  }, [logs])

  const colorByLevel = (level: string) => {
    switch (level) {
      case 'INFO':
        return 'black.800'
      case 'WARN':
        return 'orange.600'
      case 'ERROR':
        return 'red.700'
      case 'DEBUG':
        return 'blue.700'
      default:
        return 'black.800'
    }
  }

  const renderLogLevel = (l: ILog) => {
    const level = _.get(l, 'context.level.name', 'INFO')
    return (
      <HStack w='20' flexShrink={0}>
        <Text fontWeight={'medium'} color={colorByLevel(level)}>
          {level}
        </Text>
      </HStack>
    )
  }

  const renderTime = (l: ILog) => {
    const level = _.get(l, 'context.level.name', 'INFO')
    const dt = l.timestamp - firstTimestamp
    return (
      <HStack w='20' flexShrink={0}>
        <Text color={colorByLevel(level)}>{dt / 1000}</Text>
      </HStack>
    )
  }

  const renderMessage = (l: ILog) => {
    const level = _.get(l, 'context.level.name', 'INFO')
    return (
      <VStack w='full' align={'flex-start'}>
        {_.map(l.messages, (m, i) => {
          if (_.isObject(m)) {
            return (
              <ReactJson src={m} key={l.timestamp + '____' + i} collapsed={1} />
            )
          } else {
            return (
              <Text color={colorByLevel(level)} key={l.timestamp + '____' + i}>
                {l.messages[i] as string}
              </Text>
            )
          }
        })}
      </VStack>
    )
  }

  const renderLog = (l: ILog, i: number) => {
    return (
      <HStack key={l.timestamp.toString() + '-' + i} align={'flex-start'}>
        {renderLogLevel(l)}
        {renderTime(l)}
        {renderMessage(l)}
      </HStack>
    )
  }

  return (
    <VStack
      w='full'
      align='flex-start'
      p={4}
      fontSize={'xs'}
      spacing={1}
      pl={8}
    >
      {logs.map(renderLog)}
    </VStack>
  )
}

export default TwoFactorLogs
