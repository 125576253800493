import { FC } from 'react'
import { Center, VStack, Box, Text } from '@chakra-ui/react'

type Props = {
  note?: string
}

const Loading: FC<Props> = ({ note }) => {
  return (
    <Center h='full' w='full'>
      <VStack w='xs' direction='column' align='center' spacing={4}>
        <Box w='full'>
          {note && (
            <Text textAlign={'center'} fontSize='sm' color={'gray.500'}>
              {note}
            </Text>
          )}
        </Box>
      </VStack>
    </Center>
  )
}

export default Loading
