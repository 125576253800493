import { createAction } from '@reduxjs/toolkit'
import * as FaceSign from 'types/internal'

export const logout = createAction('LOGOUT')
export const receiveAuthData = createAction<{
  uid: string
  email: string | null
} | null>('RECEIVE_AUTH_DATA')

export const receiveClients =
  createAction<Record<string, FaceSign.Client>>('RECEIVE_CLIENTS')
