import { FC, useState, useEffect } from 'react'
import { Button, Flex, Text, Box, Tag, VStack } from '@chakra-ui/react'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import voicesData from './voices.json'
import Label from 'components/Label'

type Voice = {
  voice_id: string
  display_name: string
  preview: {
    movio: string
  }
  gender: string
  tags: string
  settings: {
    elevenlabs_settings: any | null
  }
  is_customer: boolean
}

type Props = {
  value?: string
  onSelect: (voiceId: string) => void
}

const VoiceSelect: FC<Props> = ({ value, onSelect }) => {
  const [voices, setVoices] = useState<Voice[]>([])
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null)

  useEffect(() => {
    const englishVoices = voicesData.data.list.filter(
      voice => voice.language === 'English' || voice.is_customer
    )
    const sortedVoices = englishVoices.sort((a, b) =>
      a.display_name.localeCompare(b.display_name)
    )
    setVoices(sortedVoices)
  }, [])

  const playAudio = (url: string) => {
    if (audio) {
      audio.pause()
    }
    const newAudio = new Audio(url)
    newAudio.play()
    setAudio(newAudio)
  }

  const selectedVoice = voices.find(voice => voice.voice_id === value)

  const renderVoiceOption = (voice: Voice) => (
    <MenuItem
      key={voice.voice_id}
      value={voice.voice_id}
      onClick={() => onSelect(voice.voice_id)}
    >
      <Flex justifyContent='space-between' alignItems='center'>
        <Flex alignItems='baseline' gap={4}>
          <Text>{voice.display_name}</Text>
          <Text fontSize='xs' color='gray.500' fontWeight='semibold'>
            {voice.gender}
          </Text>
          {voice.tags && (
            <Text fontSize='xs' color='gray.500'>
              {voice.tags}
            </Text>
          )}
          {voice.is_customer && (
            <Tag size='sm' colorScheme='orange' variant='subtle'>
              ElevenLabs
            </Tag>
          )}
        </Flex>
      </Flex>
    </MenuItem>
  )

  return (
    <VStack w='full' align={'flex-start'}>
      <Label>Voice</Label>
      <Flex alignItems='center' gap={2} w='full'>
        <Menu placement='bottom-start'>
          <MenuButton
            as={Button}
            size='sm'
            variant={'outline'}
            rightIcon={<ChevronDownIcon />}
            w='full'
          >
            {selectedVoice
              ? renderVoiceOption(selectedVoice)
              : 'Choose a voice'}
          </MenuButton>
          <MenuList maxH={'md'} overflowY='auto'>
            {voices.map(renderVoiceOption)}
          </MenuList>
        </Menu>
        <Button
          size='sm'
          isDisabled={!selectedVoice}
          onClick={() =>
            selectedVoice && playAudio(selectedVoice.preview.movio)
          }
        >
          Play
        </Button>
      </Flex>
    </VStack>
  )
}

export default VoiceSelect
