import { HStack, Tag, TagCloseButton, TagLabel, VStack } from '@chakra-ui/react'
import Label from 'components/Label'
import _ from 'lodash'
import SimpleInput from 'shared/components/SimpleInput'
import { FC } from 'react'

type Props = {
  domains: string[]
  onChange: (domains: string[]) => void
}

const ClientDomains: FC<Props> = ({ domains, onChange }) => {
  const deleteDomain = (i: number) => {
    const newDomains = [...domains]
    newDomains.splice(i, 1)
    onChange(newDomains)
  }

  const onAddDomain = (v: string) => {
    const clearString = _.trim(v)
    if (v !== '') {
      onChange([...domains, v])
    }
  }

  const renderDomains = () => {
    return (
      <HStack wrap={'wrap'} w='full'>
        {_.map(domains, (d, i) => {
          return (
            <Tag
              size={'sm'}
              key={d}
              borderRadius='full'
              variant='solid'
              colorScheme='green'
            >
              <TagLabel>{d}</TagLabel>
              <TagCloseButton onClick={() => deleteDomain(i)} />
            </Tag>
          )
        })}
      </HStack>
    )
  }
  return (
    <VStack spacing={4} align={'start'} w='full'>
      <Label>ALLOWED DOMAINS</Label>
      {renderDomains()}
      <SimpleInput
        placeholder='Enter domain to add'
        onChange={(v: string) => onAddDomain(v)}
        clearOnApply
      />
    </VStack>
  )
}

export default ClientDomains
