import _ from 'lodash'
import { AuthError, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { NavigateFunction } from 'react-router-dom'

import { auth } from 'controllers/db'

export const dbSignIn = async (
  email: string,
  password: string
): Promise<null | string> => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    return null
  } catch (e) {
    const er = e as AuthError
    console.log('error', er.code, er.message)
    return er.code
  }
}

export const dbSignOut = async (navigate: NavigateFunction): Promise<void> => {
  try {
    if (!_.isNil(auth) && !_.isNil(auth.currentUser)) {
      await signOut(auth)
    }
    navigate('/signin')
  } catch (e) {
    if (typeof e === 'string') {
      console.log('signOut error:', e.toUpperCase())
    } else if (e instanceof Error) {
      console.log('signOut error:', e.message)
    }
  }
}
