import { FC, useMemo } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import _ from 'lodash'
import LoadingPage from 'pages/LoadingPage'
// import Home from 'pages/Home'
import { useSelector } from 'model/hooks'
import SignIn from 'pages/SignIn'
import NavWrapper from 'navigation/NavWrapper'
import PageLayout from 'navigation/PageLayout'
// import Cases from 'pages/Cases'
// import Case from 'pages/Case'
import Settings from 'pages/Settings'
import Clients from 'pages/Clients'
import Client from 'pages/Client'
import TwoFactorLogs from 'pages/TwoFactorLogs'

const AppRouter: FC = () => {
  const authData = useSelector(state => state.authData)

  const privateRoutes = useMemo(() => {
    if (_.isNil(authData)) {
      return <Route path='*' element={<LoadingPage />} />
    } else {
      return (
        <Route path='/' element={<PageLayout />}>
          {/* <Route path='/case/:caseId' element={<Case />} />
          <Route path='/case' element={<Cases />} /> */}
          <Route path='logs/:interactionId' element={<TwoFactorLogs />} />
          <Route path='/clients/:clientId' element={<Client />} />
          <Route path='/clients' element={<Clients />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='*' element={<Settings />} />
        </Route>
      )
    }
  }, [authData])

  const publicRoutes = useMemo(() => {
    if (!_.isNil(authData)) {
      return <Route path='*' element={<LoadingPage />} />
    } else {
      return <Route path='/signin' element={<SignIn />} />
    }
  }, [authData])

  return (
    <BrowserRouter>
      <NavWrapper>
        <Routes>
          {privateRoutes}
          {publicRoutes}
        </Routes>
      </NavWrapper>
    </BrowserRouter>
  )
}

export default AppRouter
