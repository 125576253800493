import { FC } from 'react'
import { Text } from '@chakra-ui/react'
type Props = {
  children: string
}

const Label: FC<Props> = ({ children }) => {
  return (
    <Text fontSize='sm' color='black.900' fontWeight={'semibold'}>
      {children}
    </Text>
  )
}

export default Label
