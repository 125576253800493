import { Unsubscribe } from 'firebase/firestore'
import _ from 'lodash'
// import { UserT } from 'shared/types/model'

interface Listeners {
  [key: string]: Unsubscribe
}

let listeners: Listeners = {}

// export let existingUser: UserT | null = null

// export const setExistingUser = (user: UserT | null) => {
//   existingUser = user
// }

export function addListener (key: string, l: Unsubscribe): void {
  const existingUnsubscribe = _.get(listeners, key)
  if (_.isFunction(existingUnsubscribe)) existingUnsubscribe()
  listeners[key] = l
}

export function hasListener (key: string): boolean {
  return _.has(listeners, key)
}

export function clearListeners (): void {
  console.log('clear listeners:')
  // setExistingUser(undefined)
  for (const key in listeners) {
    console.log('clear listener', key)
    const l = _.get(listeners, key)
    // console.log('off:', l)
    !_.isNil(l) && l()
  }

  listeners = {}
}

export function clearListenersExceptUser (): void {
  console.log('clear listeners except user:')
  // setExistingUser(undefined)
  for (const key in listeners) {
    console.log('clear listener', key)
    if (key !== 'user') {
      const l = _.get(listeners, key)
      // console.log('off:', l)
      !_.isNil(l) && l()
    }
  }

  listeners = {}
}

export function offListener (key: string): void {
  const l = _.get(listeners, key)
  if (_.isFunction(l)) {
    // l.off()
    l()
    delete listeners[key]
  }
}
