import { FC, useState } from 'react'
import { VStack, HStack, Input, Button, IconButton } from '@chakra-ui/react'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import Label from 'components/Label'

type Props = {
  value: Record<string, string>
  onChange: (value: Record<string, string>) => void
}

const CannedResponse: FC<Props> = ({ value, onChange }) => {
  const [newKey, setNewKey] = useState('')
  const [newValue, setNewValue] = useState('')

  const handleEdit = (key: string, newValue: string) => {
    onChange({ ...value, [key]: newValue })
  }

  const handleAdd = () => {
    if (newKey && newValue) {
      onChange({ ...value, [newKey]: newValue })
      setNewKey('')
      setNewValue('')
    }
  }

  const handleDelete = (key: string) => {
    const newValue = { ...value }
    delete newValue[key]
    onChange(newValue)
  }

  return (
    <VStack spacing={2} align='stretch' w='full'>
      <Label>Canned responses</Label>
      {Object.entries(value).map(([key, val]) => (
        <HStack key={key}>
          <Input value={key} isReadOnly size='sm' flex={1} />
          <Input
            value={val}
            size='sm'
            onChange={e => handleEdit(key, e.target.value)}
            flex={2}
          />
          <IconButton
            w={'53px'}
            aria-label='Delete'
            variant={'ghost'}
            icon={<DeleteIcon />}
            onClick={() => handleDelete(key)}
            size={'sm'}
          />
        </HStack>
      ))}
      <HStack>
        <Input
          placeholder='New key'
          value={newKey}
          onChange={e => setNewKey(e.target.value)}
          size='sm'
          flex={1}
        />
        <Input
          placeholder='New value'
          value={newValue}
          onChange={e => setNewValue(e.target.value)}
          size='sm'
          flex={2}
        />
        <Button size='sm' onClick={handleAdd} variant={'outline'}>
          Add
        </Button>
      </HStack>
    </VStack>
  )
}

export default CannedResponse
