import { FC } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from 'react-redux'

import AppRouter from 'navigation/AppRouter'
import store from 'model/store'
import '@fontsource/inter'
import '@fontsource/inter/100.css'
import '@fontsource/inter/200.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'

const App: FC = () => {
  return (
    <Provider store={store}>
      <ChakraProvider>
        <AppRouter />
      </ChakraProvider>
    </Provider>
  )
}

export default App
