import { HStack, Text } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

type Props = {
  title: string
  actions?: ReactNode
}

const NavBar: FC<Props> = ({ title, actions }) => {
  return (
    <HStack
      h='14'
      p={4}
      justify={'space-between'}
      borderBottomWidth={1}
      borderBottomColor={'gray.200'}
    >
      <Text size='lg' fontWeight={'bold'}>
        {title}
      </Text>
      <HStack>{actions}</HStack>
    </HStack>
  )
}

export default NavBar
