import { useMemo } from 'react'
import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Divider,
  HStack,
  Text,
  VStack
} from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { useSelector } from 'model/hooks'
import { useParams } from 'react-router'
import _ from 'lodash'
import SimpleInput from 'shared/components/SimpleInput'
import { dbAddApiKey, dbUpdateClient } from 'controllers/clients'
import Label from 'components/Label'
import dayjs from 'dayjs'
import * as FaceSign from 'types/internal'
import { v6 as generate } from 'uuid'
import ApiKeyRow from 'pages/client/ApiKeyRow'
import ClientDomains from 'pages/client/ClientDomains'

const Client = () => {
  const { clientId } = useParams() as { clientId: string }
  const c = useSelector(state => _.get(state.clients, clientId))

  const sortedKeys = useMemo(() => {
    if (c && c.apiKeys) {
      return _.sortBy(c.apiKeys, 'createdAt', 'desc')
    } else {
      return []
    }
  }, [c])

  const renderKey = (k: FaceSign.ApiKey) => {
    return (
      <ApiKeyRow
        k={k}
        key={k.id}
        toggle={() =>
          dbUpdateClient(clientId, { [`apiKeys.${k.id}.enabled`]: !k.enabled })
        }
      />
    )
  }

  const generateApiKey = () => {
    const key = generate()
    const apiKey: FaceSign.ApiKey = {
      id: generate(),
      key,
      createdAt: _.now(),
      enabled: true
    }
    dbAddApiKey(c.id, apiKey)
  }

  const renderContent = () => {
    if (c) {
      return (
        <VStack>
          <Card w='lg'>
            <CardBody>
              <VStack w='full' spacing={6}>
                <VStack spacing={0} align={'start'} w='full'>
                  <Label>ID</Label>
                  <Text fontSize={'sm'}>{c.id}</Text>
                </VStack>

                <VStack w='full' spacing={0}>
                  <SimpleInput
                    label='Client name'
                    value={c.title || ''}
                    onChange={(v: string) => dbUpdateClient(c.id, { title: v })}
                  />
                </VStack>

                <VStack spacing={0} align={'start'} w='full'>
                  <Label>Created At</Label>
                  <Text fontSize={'sm'}>
                    {dayjs(c.createdAt).format('LLL')}
                  </Text>
                </VStack>

                <Divider />

                <VStack spacing={4} align={'start'} w='full'>
                  <Label>API KEYS</Label>
                  {_.map(sortedKeys, renderKey)}
                  <Button
                    variant={'link'}
                    size='sm'
                    colorScheme='blue'
                    onClick={generateApiKey}
                  >
                    + Add api key
                  </Button>
                </VStack>

                <Divider />

                <ClientDomains
                  domains={c.domains || []}
                  onChange={(domains: string[]) =>
                    dbUpdateClient(clientId, { domains })
                  }
                />

                <HStack w='full' justify={'start'}>
                  <Checkbox
                    size='sm'
                    isChecked={c.allowStandalone}
                    onChange={e =>
                      dbUpdateClient(c.id, {
                        allowStandalone: e.target.checked
                      })
                    }
                  >
                    Allow standalone
                  </Checkbox>
                </HStack>

                <HStack w='full' justify={'start'}>
                  <Checkbox
                    size='sm'
                    isChecked={c.debugInSlack}
                    onChange={e =>
                      dbUpdateClient(c.id, {
                        debugInSlack: e.target.checked
                      })
                    }
                  >
                    Debug in Slack
                  </Checkbox>
                </HStack>
              </VStack>
            </CardBody>
          </Card>
        </VStack>
      )
    }
  }

  return (
    <Box w='full' h='full' bg='white' overflow={'hidden'}>
      <NavBar title={_.get(c, 'title')} />
      <Box w='full' h='full' overflow={'hidden'} bg='gray.50' p={4}>
        {renderContent()}
      </Box>
    </Box>
  )
}

export default Client
