import React from 'react'
import { Textarea, VStack } from '@chakra-ui/react'
import Label from 'components/Label'
import ResizeTextarea from 'react-textarea-autosize'

interface JsonSchemeInputProps {
  value: string
  onChange: (jsonScheme: string) => void
}

const JsonSchemeInput: React.FC<JsonSchemeInputProps> = ({
  value,
  onChange
}) => {
  return (
    <VStack spacing={2} align='stretch' w='full'>
      <Label>JSON Schema</Label>
      <Textarea
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder='Enter your JSON schema here'
        fontSize={'sm'}
        minH='unset'
        overflow='hidden'
        w='100%'
        resize='none'
        minRows={5}
        as={ResizeTextarea}
      />
    </VStack>
  )
}

export default JsonSchemeInput
