import {
  Box,
  Flex,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  VStack
} from '@chakra-ui/react'
import { dbSignOut } from 'controllers/auth'
import { useNavigate } from 'react-router'

interface LinkItemProps {
  name: string
  path?: string
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'Settings', path: 'settings' },
  { name: 'Clients', path: 'clients' }
]

export default function SimpleSidebar () {
  const { isOpen, onClose } = useDisclosure()
  return (
    <Box minH='100vh' bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent onClose={() => onClose} />
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Box w={44}>{/* Content */}</Box>
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ ...rest }: SidebarProps) => {
  const navigate = useNavigate()
  return (
    <VStack
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: '44' }}
      pos='fixed'
      h='full'
      pt={4}
      align={'flex-start'}
      {...rest}
    >
      <VStack h='full' w='full' align={'flex-start'}>
        {LinkItems.map(link => (
          <NavItem key={link.name} path={link.path}>
            {link.name}
          </NavItem>
        ))}
      </VStack>
      <Box
        as='button'
        onClick={() => dbSignOut(navigate)}
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
      >
        <Flex
          align='center'
          p='4'
          mx='4'
          borderRadius='lg'
          role='group'
          cursor='pointer'
          _hover={{
            bg: 'cyan.400',
            color: 'white'
          }}
        >
          Sign Out
        </Flex>
      </Box>
    </VStack>
  )
}

interface NavItemProps extends FlexProps {
  children: string
  path?: string
}
const NavItem = ({ children, path, ...rest }: NavItemProps) => {
  const navigate = useNavigate()
  return (
    <Box
      as='button'
      onClick={path ? () => navigate(path) : undefined}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align='center'
        p='4'
        mx='4'
        borderRadius='lg'
        role='group'
        cursor='pointer'
        _hover={{
          bg: 'cyan.400',
          color: 'white'
        }}
        {...rest}
      >
        {children}
      </Flex>
    </Box>
  )
}
