import { configureStore } from '@reduxjs/toolkit'
import authData from 'model/reducers/authData'
import clients from 'model/reducers/clients'

const store = configureStore({
  reducer: {
    authData,
    clients
  }
})
export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
