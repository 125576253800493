import { httpsCallable } from 'firebase/functions'
import { functions } from 'controllers/db'
import _ from 'lodash'

export const callChat = async (
  prompt: string,
  model: string,
  temperature: number,
  maxTokens: number,
  isJson: boolean,
  images: string[]
) => {
  try {
    console.log('callChatgpt start')
    const callChatgpt = httpsCallable(functions, 'callChatgpt')
    const res = await callChatgpt({
      prompt,
      model,
      temperature,
      maxTokens,
      isJson,
      images
    })
    console.log('callable function res:', res)
    return res.data
  } catch (e) {
    console.warn('error', e)
    return { error: _.get(e, 'message') }
  }
}
