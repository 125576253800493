import {
  HStack,
  IconButton,
  Text,
  VStack,
  Image,
  Switch,
  useClipboard,
  useToast
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { FC, useEffect } from 'react'
import { FaceSign } from 'shared/types/api'
import iconClipboard from 'shared/assets/iconClipboard.svg'

type Props = {
  k: FaceSign.ApiKey
  toggle: () => void
}

const ApiKeyRow: FC<Props> = ({ k, toggle }) => {
  const toast = useToast()
  const { value, onCopy, hasCopied } = useClipboard(k.key)

  useEffect(() => {
    if (hasCopied) {
      toast({
        duration: 3000,
        isClosable: true,
        title: 'Copied to clipboard',
        description: value,
        status: 'success'
      })
    }
  }, [hasCopied])

  return (
    <HStack w='full' justify={'between'}>
      <VStack spacing={0} w='full' flex={1} align='start'>
        <Text fontSize={'xs'}>{k.key}</Text>
        <Text fontSize={'xs'} color='gray.400'>
          {dayjs(k.createdAt).format('lll')}
        </Text>
      </VStack>
      <HStack>
        <Switch
          size='md'
          colorScheme='teal'
          isChecked={k.enabled}
          onChange={() => toggle()}
        />
        <IconButton
          size='sm'
          aria-label='copy'
          variant={'outline'}
          icon={<Image boxSize={4} src={iconClipboard} />}
          onClick={onCopy}
        />
      </HStack>
    </HStack>
  )
}

export default ApiKeyRow
