import { Box } from '@chakra-ui/react'

const DevIndicator = () => {
  return (
    <Box
      rounded='full'
      bg='red.500'
      w={2}
      h={2}
      position={'fixed'}
      left={1}
      top={1}
    />
  )
}

export default DevIndicator
